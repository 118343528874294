import React from 'react';
import {Col, Image} from 'react-bootstrap';

import slide6 from './slide6.png';
import slide7 from './slide7.png';
import {Helmet} from 'react-helmet';

const design = () => (
    <Col>
        <Helmet>
            <title>Design | Pokedex COMP 420 Term Project</title>
        </Helmet>
        <Image src={slide6} alt={"Database Design"} fluid className={"full-width mb-5"}/>
        <Image src={slide7} alt={"UI Design"} fluid className={"full-width"}/>
    </Col>
);

export default design;
