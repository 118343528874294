import React, {Component} from 'react';
import {Col, Image} from 'react-bootstrap';

import slide1 from './slide1.png';

class Home extends Component {
    render() {
        return (
            <Col>
                <Image fluid src={slide1} alt="Project Title Slide" className="full-width"/>
            </Col>
        );
    }
}

export default Home;
