import React from 'react';

import slide3 from './slide3.png';
import slide4 from './slide4.png';
import slide5 from './slide5.png';
import {Col, Image} from 'react-bootstrap';
import {Helmet} from 'react-helmet';

const databaseProposal = () => (
    <Col>
        <Helmet>
            <title>Database Proposal | Pokedex COMP 420 Term Project</title>
        </Helmet>
        <Image src={slide3} fluid alt="Entities Slide" className="full-width"/>
        <Image src={slide4} fluid alt="First Business Rules Slide" className="full-width"/>
        <Image src={slide5} fluid alt="Second Business Rules Slide" className="full-width"/>
    </Col>
);

export default databaseProposal;
