import React from 'react';

import {Navbar, Nav, Container} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'

const navigation = () => (
    <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
            <LinkContainer to="/">
                <Navbar.Brand href="/">Pokedex</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <LinkContainer to="/">
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={"/concept"}>
                        <Nav.Link>Concept</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={"/database-proposal"}>
                        <Nav.Link>Database Proposal</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={"/design"}>
                        <Nav.Link>Design</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={"/implementation"}>
                        <Nav.Link>Implementation</Nav.Link>
                    </LinkContainer>
                    <Nav.Link href={"https://github.com/JoseRivas1998/COMP-420-term-project"}>Source Code</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
);

export default navigation;
