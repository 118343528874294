import Navigation from '../../components/Navigation/Navigation';
import {Container, Row} from 'react-bootstrap';
import {Switch, Route} from 'react-router-dom';

import './App.css';
import Home from '../Home/Home';
import Concept from '../Concept/Concept';
import DatabaseProposal from '../DatabaseProposal/DatabaseProposal';
import Design from '../Design/Design';
import Implementation from '../Implementation/Implementation';

function App() {
    return (
        <>
            <Navigation/>
            <Container className="mt-5 mb-5">
                <Row>
                    <Switch>
                        <Route path="/concept" component={Concept}/>
                        <Route path="/database-proposal" component ={DatabaseProposal}/>
                        <Route path="/design" component ={Design}/>
                        <Route path="/implementation" component ={Implementation}/>
                        <Route path="/" component={Home}/>
                    </Switch>
                </Row>
            </Container>
        </>
    );
}

export default App;
