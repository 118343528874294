import React from 'react';
import {Col, Image} from 'react-bootstrap';

import slide2 from './slide2.png';
import {Helmet} from 'react-helmet';

const concept = () => (
    <Col>
        <Helmet>
            <title>Concept | Pokedex COMP 420 Term Project</title>
        </Helmet>
        <Image fluid className={"full-width"} src={slide2} alt={"Project Concept Slide"} />
    </Col>
);

export default concept;
