import React from 'react';
import {Col, Image, ResponsiveEmbed} from 'react-bootstrap';
import slide8 from './slide8.png';
import slide9 from './slide9.png';
import slide11 from './slide11.png';
import slide12 from './slide12.png';
import slide13 from './slide13.png';
import slide14 from './slide14.png';
import slide15 from './slide15.png';
import slide16 from './slide16.png';
import slide17 from './slide17.png';
import slide18 from './slide18.png';
import slide19 from './slide19.png';
import slide20 from './slide20.png';
import {Helmet} from 'react-helmet';

const implementation = () => (
    <Col>
        <Helmet>
            <title>Implementation | Pokedex COMP 420 Term Project</title>
        </Helmet>
        <Image src={slide8} alt={"Technologies Slide"} fluid className={"full-width"}/>
        <Image src={slide9} alt={"Final ERD Slide"} fluid className={"full-width"}/>
        <h2>Demo</h2>
        <ResponsiveEmbed aspectRatio="16by9" className={"mb-3"}>
            <iframe
                src="https://www.youtube.com/embed/jMXRpQ_TM3w"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>
        </ResponsiveEmbed>
        <Image src={slide11} alt={"Login Screen"} fluid className={"full-width"}/>
        <Image src={slide12} alt={"Main Pokedex Screen"} fluid className={"full-width"}/>
        <Image src={slide13} alt={"Second Pokedex Screen"} fluid className={"full-width"}/>
        <Image src={slide14} alt={"Pikachu Pokedex Entry"} fluid className={"full-width"}/>
        <Image src={slide15} alt={"Bulbasaur Pokedex Entry"} fluid className={"full-width"}/>
        <Image src={slide16} alt={"Palkia Pokedex Entry"} fluid className={"full-width"}/>
        <Image src={slide17} alt={"Evolution Chains"} fluid className={"full-width"}/>
        <Image src={slide18} alt={"Unown Pokedex Entry"} fluid className={"full-width"}/>
        <Image src={slide19} alt={"Burmy Pokedex Entry"} fluid className={"full-width"}/>
        <Image src={slide20} alt={"Party Screen"} fluid className={"full-width"}/>
    </Col>
);

export default implementation;
